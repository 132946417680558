import React, { createContext, useContext, useReducer, useMemo, useCallback } from 'react';

const PropertyContext = createContext();

export const useProperty = () => {
  return useContext(PropertyContext);
};

const initialState = {
  ad_contributor: null, // string
  ad_id: null, // string
  ad_last_updated_date: null, // timestamp without time zone
  ad_posted_date: null, // timestamp without time zone
  additional_basement_rooms: null, // boolean
  amenity_distance: null, // number
  amenity_importance: null, // number
  amenity_popularity: null, // number
  annual_taxes: null, // number
  annual_taxes_currency: null, // string
  asking_price: null, // number
  asking_price_currency: null, // string
  asking_price_per_unit_of_area: null, // number
  available_for_video_tour: null, // boolean
  backyard_access: null, // boolean
  balconies_and_terraces: null, // number
  basement_storage: null, // boolean
  bathtub: null, // boolean
  bed: null, // boolean
  bedrooms: null, // number
  billiards_room: null, // boolean
  blinds: null, // boolean
  built_in_wardrobe: null, // boolean
  burglar_alarm: null, // boolean
  cable_TV: null, // boolean
  ceiling_height: null, // string
  child_friendly: null, // boolean
  city: null, // string
  city_block: null, // string
  city_distance: null, // number
  city_importance: null, // number
  city_popularity: null, // number
  clothes_dryer: null, // boolean
  condition: null, // string
  construction_period: null, // string
  converted_into_residential: null, // boolean
  cooling_system: null, // string
  country: null, // string
  county: null, // string
  couple_friendly: null, // boolean
  dining_table: null, // boolean
  dishwasher: null, // boolean
  district: null, // string
  energy_certificate: null, // boolean
  exterior_finish: null, // string
  exterior_joinery: null, // string
  f_85_95_avg_aesthetic: null, // number
  f_85_95_avg_exterior: null, // number
  f_85_95_avg_interior: null, // number
  f_85_95_backyard: null, // number
  f_85_95_balcony: null, // number
  f_85_95_bathroom: null, // number
  f_85_95_bedroom: null, // number
  f_85_95_building_corridor: null, // number
  f_85_95_building_exterior: null, // number
  f_85_95_dining_table: null, // number
  f_85_95_doors_and_hallways: null, // number
  f_85_95_elevator: null, // number
  f_85_95_garage_parking: null, // number
  f_85_95_house_exterior: null, // number
  f_85_95_kitchen: null, // number
  f_85_95_living_room: null, // number
  f_85_95_number_of_discarded_images: null, // number
  f_85_95_number_of_images: null, // number
  f_85_95_number_of_unfurnished: null, // number
  f_85_95_office_desk: null, // number
  f_85_95_percentage_of_discarded_images: null, // number
  f_85_95_percentage_of_unfurnished: null, // number
  f_85_95_stairs: null, // number
  f_85_95_unfurnished: null, // number
  f_85_95_view: null, // number
  facing_east: null, // boolean
  facing_north: null, // boolean
  facing_south: null, // boolean
  facing_west: null, // boolean
  family_friendly: null, // boolean
  fire_sale: null, // boolean
  fireplace: null, // boolean
  fitness_center_in_building: null, // boolean
  fixed_price: null, // boolean
  floor: null, // number
  foreigner_friendly: null, // boolean
  formatted: null, // string
  full_baths: null, // number
  furniture: null, // string
  garage_count: null, // number
  google_500m_count_cafe: null, // number
  google_500m_count_furniture_store: null, // number
  google_500m_count_gym: null, // number
  google_500m_count_hospital: null, // number
  google_500m_count_lodging: null, // number
  google_500m_count_museum: null, // number
  google_500m_count_park: null, // number
  google_500m_count_real_estate_agency: null, // number
  google_500m_count_restaurant: null, // number
  google_500m_count_school: null, // number
  google_500m_count_transit_station: null, // number
  google_500m_count_university: null, // number
  greenery_view: null, // boolean
  gym_in_house: null, // boolean
  half_baths: null, // number
  has_elevator: null, // boolean
  heating_system: null, // string
  hot_water_supply: null, // boolean
  house_type: null, // string
  indoor_pool: null, // boolean
  intercom: null, // boolean
  internet: null, // boolean
  jakuzzi: null, // boolean
  kitchen_cabinets: null, // boolean
  land_line_phone: null, // boolean
  lat: null, // number
  legal_status: null, // string
  legal_status_percentage: null, // number
  lessor_speaks_chinese: null, // boolean
  lessor_speaks_english: null, // boolean
  lessor_speaks_french: null, // boolean
  lessor_speaks_german: null, // boolean
  lessor_speaks_hungarian: null, // boolean
  lessor_speaks_other_languages: null, // boolean
  lessor_speaks_russian: null, // boolean
  lessor_speaks_serbian: null, // boolean
  lessor_speaks_spanish: null, // boolean
  lng: null, // number
  local_entropy: null, // number
  lot_size: null, // number
  lot_size_unit: null, // string
  lot_type: null, // string
  minimum_lease_term: null, // string
  model_id: null, // string
  mountain_view: null, // boolean
  move_in_date: null, // timestamp without time zone
  move_in_ready: null, // boolean
  municipality: null, // string
  neighbourhood: null, // string
  number_of_outbuildings: null, // number
  od_ac: null, // boolean
  od_ada_bridge: null, // boolean
  od_armchair: null, // boolean
  od_art_painting: null, // boolean
  od_balcony_door: null, // boolean
  od_bar_chair: null, // boolean
  od_bar_table: null, // boolean
  od_bathroom_sink: null, // boolean
  od_bathroom_vanity_with_sink: null, // boolean
  od_bathtub: null, // boolean
  od_bedroom_bed: null, // boolean
  od_bidet: null, // boolean
  od_boiler: null, // boolean
  od_bw_kula: null, // boolean
  od_cabinet: null, // boolean
  od_carpet: null, // boolean
  od_chair: null, // boolean
  od_concealed_cistern: null, // boolean
  od_cook_top: null, // boolean
  od_count: null, // number
  od_curtain: null, // boolean
  od_desk: null, // boolean
  od_desk_chair: null, // boolean
  od_dining_table: null, // boolean
  od_dishwasher: null, // boolean
  od_drying_machine: null, // boolean
  od_fireplace: null, // boolean
  od_hram_svetog_save: null, // boolean
  od_jakuzzi: null, // boolean
  od_kitchen_cabinets_bottom: null, // boolean
  od_kitchen_cabinets_full_height: null, // boolean
  od_kitchen_cabinets_top: null, // boolean
  od_kitchen_hood: null, // boolean
  od_kitchen_island: null, // boolean
  od_kitchen_sink: null, // boolean
  od_microwave: null, // boolean
  od_mirror: null, // boolean
  od_oven: null, // boolean
  od_pendant_light: null, // boolean
  od_refrigerator: null, // boolean
  od_roof_window: null, // boolean
  od_shower_base: null, // boolean
  od_single_bedroom_bed: null, // boolean
  od_sitting_set: null, // boolean
  od_stairs: null, // boolean
  od_table: null, // boolean
  od_toilet: null, // boolean
  od_toilet_cistern: null, // boolean
  od_towel_warmer: null, // boolean
  od_tv: null, // boolean
  od_walk_in_shower_enclosure: null, // boolean
  od_wardrobe: null, // boolean
  od_washing_machine: null, // boolean
  od_window: null, // boolean
  osm_500m_count_amenity_bank: null, // number
  osm_500m_count_amenity_bench: null, // number
  osm_500m_count_amenity_bicycle_parking: null, // number
  osm_500m_count_amenity_cafe: null, // number
  osm_500m_count_amenity_fast_food: null, // number
  osm_500m_count_amenity_kindergarten: null, // number
  osm_500m_count_amenity_parking_entrance: null, // number
  osm_500m_count_amenity_pharmacy: null, // number
  osm_500m_count_amenity_restaurant: null, // number
  osm_500m_count_amenity_school: null, // number
  osm_500m_count_amenity_telephone: null, // number
  osm_500m_count_amenity_university: null, // number
  osm_500m_count_amenity_waste_basket: null, // number
  osm_500m_count_highway_bus_stop: null, // number
  osm_500m_count_highway_crossing: null, // number
  osm_500m_count_highway_cycleway: null, // number
  osm_500m_count_highway_traffic_signals: null, // number
  osm_500m_count_historic_memorial: null, // number
  osm_500m_count_leisure_fitness_centre: null, // number
  osm_500m_count_leisure_fitness_station: null, // number
  osm_500m_count_leisure_park: null, // number
  osm_500m_count_leisure_picnic_table: null, // number
  osm_500m_count_leisure_playground: null, // number
  osm_500m_count_man_made_surveillance: null, // number
  osm_500m_count_natural_tree: null, // number
  osm_500m_count_public_transport_platform: null, // number
  osm_500m_count_public_transport_station: null, // number
  osm_500m_count_railway_switch: null, // number
  osm_500m_count_railway_tram_level_crossing: null, // number
  osm_500m_count_shop_car_repair: null, // number
  osm_500m_count_shop_convenience: null, // number
  osm_500m_count_shop_hairdresser: null, // number
  osm_500m_count_shop_kiosk: null, // number
  osm_500m_count_shop_laundry: null, // number
  osm_500m_count_shop_mobile_phone: null, // number
  osm_500m_count_shop_supermarket: null, // number
  osm_500m_count_shop_ticket: null, // number
  osm_500m_count_shop_travel_agency: null, // number
  osm_500m_count_tourism_information: null, // number
  other_view: null, // boolean
  outdoor_pool: null, // boolean
  panoramic_city_view: null, // boolean
  park_view: null, // boolean
  parking_count: null, // number
  parking_type: null, // string
  pet_friendly: null, // boolean
  playroom: null, // boolean
  price_period: null, // string
  property_area: null, // number
  property_area_unit: null, // string
  property_type: null, // string
  quarter: null, // string
  range_hood: null, // boolean
  real_estate_exchange_option: null, // boolean
  reception_or_security: null, // boolean
  refrigerator: null, // boolean
  region: null, // string
  registered_property_area: null, // number
  registered_property_area_unit: null, // string
  rent_payment_frequency: null, // string
  rental_price: null, // number
  rental_price_currency: null, // string
  rental_price_per_unit_of_area: null, // number
  road: null, // string
  sat_r101_apron: null, // number
  sat_r101_baseball_field: null, // number
  sat_r101_basketball_field: null, // number
  sat_r101_beach: null, // number
  sat_r101_bridge: null, // number
  sat_r101_cemetery: null, // number
  sat_r101_commercial: null, // number
  sat_r101_count: null, // number
  sat_r101_farmland: null, // number
  sat_r101_golf_course: null, // number
  sat_r101_greenhouse: null, // number
  sat_r101_helipad: null, // number
  sat_r101_lake_pond: null, // number
  sat_r101_oil_field: null, // number
  sat_r101_orchard: null, // number
  sat_r101_park: null, // number
  sat_r101_parking_lot: null, // number
  sat_r101_pier: null, // number
  sat_r101_port: null, // number
  sat_r101_quarry: null, // number
  sat_r101_railway: null, // number
  sat_r101_residential: null, // number
  sat_r101_river: null, // number
  sat_r101_roundabout: null, // number
  sat_r101_runway: null, // number
  sat_r101_sea: null, // number
  sat_r101_soccer: null, // number
  sat_r101_solar_panel: null, // number
  sat_r101_sparse_shrub: null, // number
  sat_r101_stadium: null, // number
  sat_r101_storage_tank: null, // number
  sat_r101_tennis_court: null, // number
  sat_r101_train_station: null, // number
  sat_r101_wastewater_plant: null, // number
  sat_r101_water: null, // number
  sat_r101_wind_turbine: null, // number
  sat_r101_woodland: null, // number
  sat_r101_works: null, // number
  sat_r50_apron: null, // number
  sat_r50_baseball_field: null, // number
  sat_r50_basketball_field: null, // number
  sat_r50_beach: null, // number
  sat_r50_bridge: null, // number
  sat_r50_cemetery: null, // number
  sat_r50_commercial: null, // number
  sat_r50_count: null, // number
  sat_r50_farmland: null, // number
  sat_r50_golf_course: null, // number
  sat_r50_greenhouse: null, // number
  sat_r50_helipad: null, // number
  sat_r50_lake_pond: null, // number
  sat_r50_oil_field: null, // number
  sat_r50_orchard: null, // number
  sat_r50_park: null, // number
  sat_r50_parking_lot: null, // number
  sat_r50_pier: null, // number
  sat_r50_port: null, // number
  sat_r50_quarry: null, // number
  sat_r50_railway: null, // number
  sat_r50_residential: null, // number
  sat_r50_river: null, // number
  sat_r50_roundabout: null, // number
  sat_r50_runway: null, // number
  sat_r50_sea: null, // number
  sat_r50_soccer: null, // number
  sat_r50_solar_panel: null, // number
  sat_r50_sparse_shrub: null, // number
  sat_r50_stadium: null, // number
  sat_r50_storage_tank: null, // number
  sat_r50_tennis_court: null, // number
  sat_r50_train_station: null, // number
  sat_r50_wastewater_plant: null, // number
  sat_r50_water: null, // number
  sat_r50_wind_turbine: null, // number
  sat_r50_woodland: null, // number
  sat_r50_works: null, // number
  sauna: null, // boolean
  security_deposit: null, // number
  security_deposit_currency: null, // string
  security_door: null, // boolean
  shannon_entropy: null, // number
  shower_cabin: null, // boolean
  smart_home_system: null, // boolean
  smoking_allowed: null, // boolean
  sofa_set: null, // boolean
  staff_quarters: null, // boolean
  state: null, // string
  state_district: null, // string
  storage_cabinets: null, // boolean
  stories: null, // number
  street: null, // string
  street_distance: null, // number
  street_importance: null, // number
  street_number: null, // string
  street_popularity: null, // number
  structure: null, // string
  student_friendly: null, // boolean
  study_room: null, // boolean
  subdivision: null, // string
  suburb: null, // string
  suitable_for_office_space: null, // boolean
  swimming_pool: null, // boolean
  top_floor: null, // boolean
  total_monthly_expenses: null, // number
  total_monthly_expenses_currency: null, // string
  town: null, // string
  transaction_type: null, // string
  tv: null, // boolean
  type_of_apartment: null, // string
  under_mortgage: null, // boolean
  vat_refundable: null, // boolean
  video_surveillance: null, // boolean
  village: null, // string
  walk_score: null, // number
  washing_machine: null, // boolean
  water_view: null, // boolean
  wheelchair_ramp: null, // boolean
  wine_cellar: null, // boolean
  with_existing_tenant: null, // boolean
  worker_friendly: null, // boolean
  year_of_construction: null, // number
};

const propertyReducer = (state, action) => {
  switch (action.type) {
    case 'UPDATE_PROPERTY_DATA':
      return { ...state, ...action.payload };
    case 'RESET_PROPERTY_DATA':
      return initialState;
    default:
      return state;
  }
};

export const PropertyProvider = ({ children }) => {
  const [state, dispatch] = useReducer(propertyReducer, initialState);

  const updatePropertyData = useCallback((newData) => {
    let flattenedData = { ...newData };
    if (newData.satellite_image_analysis && newData.satellite_image_analysis.length > 0) {
      newData.satellite_image_analysis.forEach((analysis) => {
        flattenedData = { ...flattenedData, ...analysis.result };
      });
      delete flattenedData.satellite_image_analysis;
    }

    if (newData.nearby_places) {
      flattenedData = { ...flattenedData, ...newData.nearby_places };
      delete flattenedData.nearby_places;
    }
    if (newData.osm_counts) {
      flattenedData = { ...flattenedData, ...newData.osm_counts };
      delete flattenedData.osm_counts;
    }

    // Filter out any fields that are not in the initialState
    const filteredData = Object.keys(flattenedData)
      .filter(key => key in initialState)
      .reduce((obj, key) => {
        obj[key] = flattenedData[key];
        return obj;
      }, {});

    dispatch({ type: 'UPDATE_PROPERTY_DATA', payload: filteredData });
  }, []);

  const resetPropertyData = useCallback(() => {
    dispatch({ type: 'RESET_PROPERTY_DATA' });
  }, []);

  const contextValue = useMemo(() => ({
    propertyData: state,
    updatePropertyData,
    resetPropertyData,
  }), [state, updatePropertyData, resetPropertyData]);

  return (
    <PropertyContext.Provider value={contextValue}>
      {children}
    </PropertyContext.Provider>
  );
};