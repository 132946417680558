import axios from 'axios';

const apiKeys = {
  googleApiKey: process.env.REACT_APP_GOOGLE_API_KEY,
  mapboxKey: process.env.REACT_APP_MAPBOX_KEY,
  walkscoreKey: process.env.REACT_APP_WALKSCORE_KEY,
  geoapifyKey: process.env.REACT_APP_GEOAPIFY_KEY,
};

export const performReverseGeocoding = async (lat, lng) => {
  const baseUrl = 'https://api.geoapify.com/v1/geocode/reverse';
  const apiParams = `lat=${lat}&lon=${lng}&lang=en&limit=1&format=json&apiKey=${apiKeys.geoapifyKey}`;

  const urls = {
    city: `${baseUrl}?${apiParams}&type=city`,
    street: `${baseUrl}?${apiParams}&type=street`,
    amenity: `${baseUrl}?${apiParams}&type=amenity`,
  };

  try {
    const [cityResponse, streetResponse, amenityResponse] = await Promise.all([
      axios.get(urls.city),
      axios.get(urls.street),
      axios.get(urls.amenity),
    ]);

    console.log('City Response:', cityResponse.data);
    console.log('Street Response:', streetResponse.data);
    console.log('Amenity Response:', amenityResponse.data);

    const cityData = cityResponse.data.results[0] || {};
    const streetData = streetResponse.data.results[0] || {};
    const amenityData = amenityResponse.data.results[0] || {};

    return {
      country: cityData.country || "",
      region: cityData.region || "",
      state: cityData.state || "",
      state_district: cityData.state_district || "",
      county: cityData.county || "",
      city: cityData.city || "",
      city_importance: cityData.rank?.importance || "",
      city_popularity: cityData.rank?.popularity || "",
      town: cityData.town || "",
      village: cityData.village || "",
      municipality: streetData.municipality || "", 
      district: streetData.district || "",
      neighbourhood: streetData.neighbourhood || streetData.suburb || "",
      suburb: streetData.suburb || "",
      quarter: streetData.quarter || "",
      city_block: streetData.city_block || "",
      road: streetData.road || "",
      street: streetData.street || "",
      street_importance: streetData.rank?.importance || "",
      street_popularity: streetData.rank?.popularity || "",
      street_number: amenityData.housenumber || "",
      subdivision: streetData.subdivision || "",
      amenity_importance: amenityData.rank?.importance || "",
      amenity_popularity: amenityData.rank?.popularity || "",
      lat,
      lng,
      formatted: amenityData.formatted || streetData.formatted || cityData.formatted || "",
      city_distance: cityData.distance !== undefined ? cityData.distance : "",
      street_distance: streetData.distance !== undefined ? streetData.distance : "",
      amenity_distance: amenityData.distance !== undefined ? amenityData.distance : "",
    };
  } catch (error) {
    console.error('Error fetching Geoapify data:', error);
    return {
      country: "",
      region: "",
      state: "",
      state_district: "",
      county: "",
      city: "",
      municipality: "",
      city_importance: "",
      city_popularity: "",
      town: "",
      village: "",
      district: "",
      neighbourhood: "",
      suburb: "",
      quarter: "",
      city_block: "",
      road: "",
      street: "",
      street_importance: "",
      street_popularity: "",
      street_number: "",
      subdivision: "",
      amenity_importance: "",
      amenity_popularity: "",
      lat,
      lng,
      formatted: "",
      city_distance: "",
      street_distance: "",
      amenity_distance: "",
    };
  }
};

export const getSatelliteImagery = async (lat, lng, zoom = 18) => {
  const deg2num = (lat_deg, lng_deg, zoom) => {
    const lat_rad = (lat_deg * Math.PI) / 180;
    const n = 2.0 ** zoom;
    const xtile = Math.floor((lng_deg + 180.0) / 360.0 * n);
    const ytile = Math.floor((1.0 - Math.asinh(Math.tan(lat_rad)) / Math.PI) / 2.0 * n);
    return { xtile, ytile };
  };

  const { xtile, ytile } = deg2num(lat, lng, zoom);
  const response = await axios.get(
    `https://api.tiles.mapbox.com/v4/mapbox.satellite/${zoom}/${xtile}/${ytile}.png?access_token=${apiKeys.mapboxKey}`,
    { responseType: 'arraybuffer' }
  );
  const buffer = response.data;
  const base64String = btoa(
    new Uint8Array(buffer).reduce((data, byte) => data + String.fromCharCode(byte), '')
  );
  return base64String;
};

export const getNearByPlaces = async (lat, lng, useGoogle = false) => {
  if (!useGoogle) return {};
  const radius = 500; // Radius set to 500 meters
  const places = [
    'park',
    'restaurant',
    'furniture store',
    'university',
    'gym',
    'school',
    'cafe',
    'transit station',
    'hospital',
    'lodging',
    'museum',
    'real estate agency',
  ];
  const newCounts = {};
  for (let i = 0; i < places.length; i++) {
    const value = places[i];
    const response = await axios.get(
      `/api/google-places?location=${lat},${lng}&radius=${radius}&type=${value}`
    );
    newCounts[`google_500m_count_${value.replace(/\s+/g, '_')}`] = response.data.results.length;
  }
  return newCounts;
};

export const getWalkScore = async (lat, lng, address) => {
  const response = await axios.get(
    `/api/walkscore?format=json&address=${address}&lat=${lat}&lon=${lng}&transit=1&bike=1`
  );
  return response.data;
};

export const getOSMCounts = async (lat, lng) => {
  const radius = 500; // Radius set to 500 meters
  const categories = [
    'amenity=bank', 'amenity=bench', 'amenity=bicycle_parking', 'amenity=cafe', 'amenity=fast_food',
    'amenity=kindergarten', 'amenity=parking_entrance', 'amenity=pharmacy', 'amenity=restaurant',
    'amenity=school', 'amenity=telephone', 'amenity=university', 'amenity=waste_basket', 'highway=bus_stop',
    'highway=crossing', 'highway=cycleway', 'highway=traffic_signals', 'historic=memorial', 'leisure=fitness_centre',
    'leisure=fitness_station', 'leisure=park', 'leisure=picnic_table', 'leisure=playground', 'man_made=surveillance', 'natural=tree',
    'public_transport=platform', 'public_transport=station', 'railway=switch', 'railway=tram_level_crossing',
    'shop=car_repair', 'shop=convenience', 'shop=hairdresser', 'shop=kiosk', 'shop=laundry', 'shop=mobile_phone',
    'shop=supermarket', 'shop=ticket', 'shop=travel_agency', 'tourism=information'
  ];

  // Format query for all categories in a single Overpass request
  const overpassQuery = `[out:json];
    (
      ${categories.map(category => `node[${category}](around:${radius},${lat},${lng});`).join('\n')}
    );
    out body;`;

  let retryCount = 0;
  const maxRetries = 3;

  const counts = {};
  for (const category of categories) {
    counts[`osm_500m_count_${category.replace('=', '_')}`] = null; // Initialize all counts as null
  }

  while (retryCount < maxRetries) {
    try {
      const response = await axios.get(`https://overpass-api.de/api/interpreter?data=${encodeURIComponent(overpassQuery)}`);
      const data = response.data.elements;

      // Reset counts for successful categories
      categories.forEach(category => {
        const count = data.filter(element => element.tags && element.tags[category.split('=')[0]] === category.split('=')[1]).length;
        counts[`osm_500m_count_${category.replace('=', '_')}`] = count || 0;
      });

      // Check if all categories were resolved
      const missingCategories = Object.entries(counts).filter(([_, value]) => value === null);
      if (missingCategories.length === 0) break; // Exit if no categories are missing

    } catch (error) {
      console.error(`Error fetching OSM data on attempt ${retryCount + 1}:`, error);
    }

    retryCount++;
    console.warn(`Retrying (${retryCount}/${maxRetries})...`);
  }

  if (retryCount === maxRetries) {
    console.warn('Max retries reached. Some categories may have null values.');
  }

  return counts;
};

const API_URL = 'https://contributionapi.revaluer.app/api/submit-property-data'; // Update with your actual API URL

export const submitPropertyData = async (propertyData) => {
  try {
    const response = await axios.post(API_URL, propertyData);
    return response.data;
  } catch (error) {
    console.error('Error submitting property data:', error);
    throw new Error('Failed to submit property data. Please try again.');
  }
};